import React from "react"
import { Text } from "../Core"
import { Columns, Section } from "../Containers"
import Accordion from "../accordion/Accordion"

const FeaturedList = ({
  listContent,
  headingSideColumnIs,
  listSideColumnIs
}) => {
  const { featuredListHeading, featuredListItems } = listContent
  return (
    <Section colorBack className="rw rounded">
      <Columns sideColumnsSize={2}>
        <div className="column">
          <div className="rw__container">
            <Text
              className="h3-style color-blue-dark mt-0 px-40--mobile"
              text={featuredListHeading}
            />
            <div className="rw__list">
              {featuredListItems.map((card, i) => (
                <div
                  key={card.featuredListItem.featuredListItemHeading}
                  className="rw__accordion">
                  <Accordion
                    number={i + 1}
                    heading={card.featuredListItem.featuredListItemHeading}>
                    <Text
                      useStringReplace
                      as="p"
                      className="rw__card-blurb"
                      text={card.featuredListItem.featuredListItemBlurb}
                    />
                  </Accordion>
                </div>
              ))}
            </div>
          </div>
        </div>
      </Columns>
    </Section>
    // <div className="body-section featured-list color-back">
    //   <div className="columns" style={{ paddingBottom: "32px" }}>
    //     <div className={`column is-${headingSideColumnIs}`}></div>
    //     <div className="column has-text-centered mobile-left">
    //       <Text text={featuredListHeading} />
    //     </div>
    //     <div className={`column is-${headingSideColumnIs}`}></div>
    //   </div>
    //   <div className="columns">
    //     <div className={`column is-${listSideColumnIs}`}></div>
    //     <div className="column">
    //       {featuredListItems.map((item, i) => {
    //         return (
    //           <div
    //             key={item.featuredListItem.featuredListItemHeading}
    //             className="reasons-why-grid-row-single px-40--mobile">
    //             <div></div>
    //             <div className="reason-number-wrapper">
    //               <span className="reason-number-circle">{i + 1}</span>
    //             </div>
    //             <div>
    //               {item.featuredListItem.featuredListItemHeading && (
    //                 <Text
    //                   as="h3"
    //                   text={item.featuredListItem.featuredListItemHeading}
    //                 />
    //               )}
    //               {item.featuredListItem.featuredListItemBlurb && (
    //                 <Text
    //                   useStringReplace
    //                   className="large-p"
    //                   text={item.featuredListItem.featuredListItemBlurb}
    //                 />
    //               )}
    //             </div>
    //           </div>
    //         )
    //       })}
    //     </div>
    //     <div className={`column is-${listSideColumnIs}`}></div>
    //   </div>
    // </div>
  )
}

export default FeaturedList
